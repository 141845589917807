@use '../utils/variables' as v;

@font-face {
  font-family: ITC Garamond Std Light;
  src: url(../assets/ITCGaramondStd-LtNarrow.otf) format("opentype");
} 
@font-face {
  font-family: ITC Garamond Std Regular;
  src: url(../assets/ITCGaramondBookNarrowRegular.otf) format("opentype");
} 
@font-face {
  font-family: PP Neue Montreal;
  font-weight: 400;
  src: url(../assets/PPNeueMontreal-Book.otf) format("opentype");
}
@font-face {
  font-family: PP Neue Montreal;
  font-weight: 530;
  src: url(../assets/PPNeueMontreal-Medium.otf) format("opentype");
}


body, .menu__el a {
  font-family: PP Neue Montreal, sans-serif;
  font-size: var(--text);
  line-height: 1.45;
  color: $c-text;
  font-weight: 400;
}
.menu__el a{
  color: #cdcdcd;
  mix-blend-mode: difference;
}

.menu__el a {
  font-family: ITC Garamond Std Light, serif;
  font-size: 79px;
  @media (min-width: $q-tablet2){
    font-size: 98px;
  }
  @media (min-width: $q-laptop){
    font-family: PP Neue Montreal, sans-serif;
    font-size: var(--text);
  }
}

.menu__flo a, .typo-diff, .title {
  font-family: ITC Garamond Std Light, serif;
}

/* titles */
.title{
  font-family: ITC Garamond Std Regular;
  font-weight: normal;
  line-height: 1;
  font-size: var(--title);
  &--big{
    font-family: PP Neue Montreal, sans-serif;
    font-weight: 400;
    font-size: var(--titlebig);
    line-height: 1;
  }
  &--little{
    font-family: PP Neue Montreal, sans-serif;
    font-weight: 400;
    font-size: var(--titlelittle);
    text-transform: uppercase;
  }
}
.projects .title{
  max-width: 15ch;
}
.typo-dif{
  font-family: ITC Garamond Std Light, serif;
  font-size: var(--titlespan);
  line-height: 0.72;
  vertical-align: 1%;
}
.scroll-indication{
  font-size: var(--footer);
}

.skills{
  font-size: 27px;
  font-weight: 530;
  text-transform: uppercase;
}



/* LAST */ 

.last{
  .txt{
    font-size: 34px;
    font-weight: 400;
    span{
      font-size: 39px;
      font-family: ITC Garamond Std Light;
      font-weight: normal;
      vertical-align: 1%;
      line-height: 0.72;
    }
    @media (min-width: $q-laptop){
      font-size: 38px;
      span{
      font-size: 43px;
      }
    }
    @media (min-width: $q-laptop){
      font-size: 42px;
      span{
      font-size: 47px;
      }
    }
  }
}