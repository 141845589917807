@import 'utils/variables';
@import 'components/type';
@import 'components/background-anim';


*{
    margin: 0;
    padding: 0;
}
html {
	scroll-behavior: smooth;
}
ul{
    padding: 0;
    list-style: none;
}
li{
    list-style-type: none;
}
a{
    text-decoration: none;
    cursor: pointer;
    color: $c-text;
}
body{
    background-color: $c-bg;
}
/* disable scroll when menu is open */
.disable-scrolling{
    height: 100%;
    overflow: hidden;
}

/* NAV */ 

.menu{
    display: flex;
    align-items: center;
    margin: 32px 33px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 60;
    mix-blend-mode: difference;
    @media (min-width: $q-mobile1){
        margin: 32px 30px;
    }
    @media (min-width: $q-laptop){
        margin: 34px 44px;
    }
    @media (min-width: $q-desktop3){
        margin: 34px 132px;
    }

    &__flo{
        flex-grow: 1;
        z-index: 65;
    }
    &__liste{
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0%;
        opacity: 0;
        z-index: -15;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 40px;
        mix-blend-mode: none;
        //background-color: $c-bg;
        //transition: transform 0.4s ease-out;
        .menu__el{
            &:hover{
                transform: scale(1.02);
                transition: transform 0.3s;
            }
        }
        @media (min-width: $q-laptop){
            display: flex;
            opacity: 1;
            z-index: 50;
            pointer-events: initial;
            gap: 44px;
            position: relative;
            width: auto;
            height: auto;
            left: 0;
            flex-direction: initial;
            background-color: initial;
            mix-blend-mode: difference;
        }
    }
    &__burger{
        z-index: 60;
        background-color: transparent;
        position: relative;
        height: 16px;
        width: 32px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 0;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        &:active, &:hover{
            span:nth-child(2){
                top: 2px;
            }
            span:nth-child(4){
                top: 14px;
            }
        }
        span:not(span:first-child) {
            display: block;
            position: absolute;
            height: 1px;
            width: 32px;
            background: $c-bg;
            opacity: 1;
            right: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
        }
        span:nth-child(2) {
            top: 0px;
          }
        span:nth-child(3) {
            top: 8px;
          }
        span:nth-child(4) {
            top: 16px;
          }
        @media (min-width: $q-laptop){     
            display: none;
        }
    }
}

@keyframes textSlide {
    from {
        color: transparent;
        transform: translateY(10px);
    }

    to {
        color: var(--color-text);
        transform: translateY(0px);
    }
}

/* ouverture menu*/
body[data-menu] .menu__liste {
    padding: 0 33px;
    opacity: 1;
    z-index: 50;
    pointer-events: initial;
    animation: textSlide 1s ease-out;
    animation-delay: -0.03s;
    //transition: transform 0.5s ease-in-out;
    @media (min-width:$q-mobile1){
        padding: 0px 30px;
    }
    @media (min-width: $q-laptop){
        transform: none;
    }
}

/* anim icon menu */
body[data-menu] .menu__burger span:nth-child(2) {
    top: 8px;
    transform: rotate(-135deg);
}
body[data-menu] .menu__burger span:nth-child(3) {
    opacity: 0;
    right: -32px;
}
body[data-menu] .menu__burger span:nth-child(4) {
    top: 8px;
    transform: rotate(135deg);
}


body[data-menu] .skills{
    display: none;
}
body[data-menu] main *:not(.circle-yellow, .circle-violet){
    opacity: 0;
    cursor: none;
    /* @media (min-width:$laptop){
        
    } */
}
body[data-menu] main{
    background-color: $c-bg;
}

/* logo */
.menu__flo a{
    font-size: 22px;
    color: #f0f0f0;
    mix-blend-mode: difference;
    padding: 4px 10px 2px;
    border: 1px solid #ffffff;
    border-radius: 20px;
    @media (min-width:$q-desktop){
        font-size: 25px;
    }
}

/*MAIN*/
.main{
    //margin-top: 93.89px;
    overflow: hidden;
    z-index: 0;
    /* @media (min-width:$q-laptop){
        margin-top: 104px;
    } */
    @media (min-width:$q-desktop3){
        margin: 0px 88px 0px;
    }
}

/* ABOUT */ 
.about{
    margin: 0 33px;
    padding-top: 141.89px;
    /* 48px + 93.89px*/
    .title--big{
        max-width: 9ch;
    }
    .txt{
        max-width: 34ch;
        padding-top: 160px;
    }
    .scroll-indication{
        position: fixed;
        display: flex;
        mix-blend-mode: difference;
        gap: 8px;
        z-index: 1;
        right: 33px;
        bottom: 32px;
        opacity: 1;
        p{
            color: #b9b9b9;
            mix-blend-mode: difference;
        }
        @media (min-width:$q-mobile1){
            right: 30px;
            bottom: 32px;
        }
        @media (min-width:$q-laptop){
            right: 50%;
            bottom: 40px;
            transform: translateX(50%);
        }
        @media (min-width:$q-desktop3){
            right: 50%;
            bottom: 88px;
        }
    }

    .skills{
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        max-width: 390px;
        margin-top: 192px;
        margin-bottom: 80px;
        &__el{
            display: inline-block;
            padding: 6px 30px 7px;
            border: 1px solid #0F0F0F;
            border-radius: 36px;
            width: fit-content;
            &:nth-child(1) {
                transform: rotate(-30deg);
                margin-bottom: 24px;
                align-self: start;
            }&:nth-child(2) {
                transform: rotate(13deg);
                margin-bottom: 40px;
                align-self: end;
            }
            &:nth-child(3) {
                transform: rotate(-15deg);
                margin-bottom: 16px;
            }
            &:nth-child(4) {
                transform: rotate(3deg);
                align-self: end;
            }
            @media (min-width: $q-mobile1){
                &:nth-child(1) {
                    margin-bottom: 20px;
                }
            }
            @media (min-width:$q-laptop){
                padding: 6px 40px 7px;
                max-width: none;
                &:nth-child(2) {
                    margin-bottom: 28px;
                }
                &:nth-child(3) {
                    margin-bottom: 10px;
                    transform: rotate(-15deg) translateX(-31px);
                }
            }
        }
        @media (min-width: 750px){
            margin-top: 0px;
            transform: translateY(-157px);
        }
        @media (min-width:$q-laptop){
            margin-top: 137px;
            transform: translateY(0px);
        }
        @media (min-width:$q-desktop2){
            margin-top: 171px;
        }
    }

    /* responsive tablet pour skills */

    .about__el{
        @media (min-width: 750px){
            display: flex;
            justify-content: space-between;
        }
        @media (min-width:$q-laptop){
            display: initial;
            
        }
    }

    @media (min-width:$q-mobile1){
        margin: 0px 30px;
    }
    @media (min-width:$q-laptop){
        display: flex;
        justify-content: space-between;
        margin: 0px 44px;
        padding-top: 126px;
        /* 22px + 104px*/
        .txt{
            padding-top: 0px;
        }
    }
    @media (min-width:$q-desktop2){
        padding-top: 192px;
        /* 88px + 104px*/
    }
    @media (min-width:$q-desktop3){
        margin: 0px 132px;
    }
}

body[data-menu] .scroll-indication{
    position: relative;
    z-index: -1;
}

.scroll-icon {
    position: absolute;
    top: -2px;
    right: -12px;
    animation: animate 1s infinite;
    stroke: #b3b3b3;
    mix-blend-mode: difference;
}
@keyframes animate{
    0%{
        margin-top: 0;
    }
    59%{
        margin-top: 5px;
    }
    100%{
        margin-top: 0;
    }
}


/* PROJECTS */
.projects{
    margin: 0 33px 0;
    padding-top: 20vh;
    .left-side__el{
        display: flex;
        flex-direction: column;
        margin-bottom: 20vh;
        .title{
            margin-bottom: 16px;
            &--little{
                margin-bottom: 8px;
            }
        }
    }
    .txt{
        order: 4;
        margin-top: 24px;
        @media (min-width:$q-laptop){
            max-width: 43ch;
        }
    }
    .work-l{
        width: 100%;
        -webkit-box-shadow: 9px 13px 18px 0px rgba(166,166,166,0.45);
        -moz-box-shadow: 9px 13px 18px 0px rgba(166,166,166,0.45);
        box-shadow: 9px 13px 18px 0px rgba(166,166,166,0.45);
        @media (min-width:$q-laptop){
            //max-width: 442px;
            display: none;
        }
    }

    .work-r{
        width: 100%;
        height: 100%;
        position: absolute;
        //max-width: 442px;
    }

    .right-side{
        display: none;
    }

    @media (min-width:$q-mobile1){
        margin: 0px 30px;
    }
    @media (min-width:$q-mobile2){
        margin: 0px 60px;
    }
    @media (min-width:$q-tablet1){
        margin: 0px 90px;
    }
    @media (min-width:$q-tablet2){
        margin: 0px 120px;
    }
    @media (min-width:$q-tablet3){
        margin: 0px 132px;
    }
    @media (min-width:$q-tablet4){
        margin: 0px 160px;
    }
    @media (min-width:850px){
        margin: 0px 184px;
    }
    @media (min-width:$q-laptop){
        display: flex;
        margin: 0px 90px;
        justify-content: space-between;
    }
    @media (min-width:$q-desktop){
        margin: 0px 110px;
    }
    @media (min-width: 1300px){
        .txt{
            max-width: 48ch;
        }
    }
    @media (min-width:$q-desktop2){
        margin: 0px 132px;
        .txt{
            max-width: 48ch;
        }
    }
    @media (min-width:$q-desktop3){
        justify-content: space-around;
    }
}


/* Projects LAPTOP */
.projects{
    .cta{
        display: none;
    }
    @media (min-width:$q-laptop){
        .left-side{
            min-height: 100vh;
        }
        .left-side__el{
            margin-bottom: 50vh;
        }
        .ctas{
            order: 4;
            display: flex;
            gap: 24px;
        }
        .cta{
            display: inline-block;
            font-size: var(--link);
            color: $c-bg;
            line-height: 1;
            width: fit-content;
            margin-top: 16px;
            padding: 10px 40px;
            border-image: initial;
            border-radius: 54px;
	        background-color: $c-text;
            order: 4;
            box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.18);
            transition: all 0.5s ease;
            overflow: hidden;
            position: relative;
            &:hover{
                //color: black;
            }
            &:before {
                --size: 0;
                content: '';
                position: absolute;
                left: var(--x);
                top: var(--y);
                width: var(--size);
                height: var(--size);
                background: radial-gradient(circle closest-side, rgb(107, 107, 107), transparent);
                transform: translate(-50%, -50%);
                transition: width .2s ease, height .2s ease;
            }
            &:hover::before {
                --size:15vw;
            }
            span{
                position: relative;
            }
            &--2:before{
                background: radial-gradient(circle closest-side, rgb(36 127 121), transparent);
            }
            &--3:before{
                background: radial-gradient(circle closest-side, rgb(84 76 137), transparent);
            }
            &--4:before{
                background: radial-gradient(circle closest-side, rgb(109 109 103), transparent);
            }
            &--5:before{
                background: radial-gradient(circle closest-side, rgb(101 77 73), transparent);
            }

        }
        .right-side{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            max-width: 390px;
            max-height: 390px;
            position: relative;
            overflow: hidden;
            //margin: 0 auto;
            box-shadow: 9px 13px 18px 0px rgb(133 132 132 / 19%);

        }
    }
    @media (min-width: 1150px){
        .right-side{
            max-width: 442px;
            max-height: 442px;

        }
    }
    @media (min-width:$q-desktop){
        .right-side{
            max-width: 442px;
            max-height: 442px;

        }
    }
}


/* LAST */ 

.last{
    margin: 0px 33px;
    .txt{
      margin-bottom: 19px;
    }
    @media (min-width:$q-mobile1){
        margin: 0px 30px;
    }
    @media (min-width:$q-laptop){
        margin: 0px 44px;
    }
    /*@media (min-width: $q-desktop2){
        .txt{
            margin-bottom: 142px;
          }
    } */
}





/* FOOTER */
footer{
    background-color: transparent;
    .place{
        display: none;
    }
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 30px;
    border-top: 1px solid $c-text;
    a{
        color: $c-text; 
        font-size: var(--footer);
        &:hover{
            transform: scale(1.02);
            transition: transform 0.3s;
        }
    }
    ul li:hover{
        transform: scale(1.02);
        transition: transform 0.3s;
    }
    
    @media (min-width: $q-laptop){
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 30px;
        /*bottom: 0;
        left: 0;
        right: 0; */
        border-top: 1px solid $c-text;
        a{
            font-size: var(--footer);
        }
        ul{
            display: flex;
            gap: 44px;
        }
        .place{
            display: initial;
        }
    }
    @media (min-width: $q-desktop2){
        padding: 44px;
    }
    @media (min-width: $q-desktop3){
        padding: 44px;
        margin: 0px 88px;
    }
}