$c-bg: #FCFCFC;
$c-alt: #FCFCFC;
$c-text: #0F0F0F;

$q-mobile1: 380px;
$q-mobile2: 450px;
$q-tablet1: 540px;
$q-tablet2: 650px;
$q-tablet3: 730px;
$q-tablet4: 800px;
$q-laptop: 1024px;
$q-desktop: 1200px;
$q-desktop2: 1450px;
$q-desktop3: 1600px;

:root{
    --footer: 15px;
    --text: 18px;
    --link: 22px;
    --titlelittle: 15px;
    --title: 42px;
    --titlebig: 52px;
    --titlespan: 58px;
}


@media (min-width: $q-mobile1) {
    :root{
        --titlebig: 60px;
        --titlespan: 68px;
    }
}
@media (min-width: $q-tablet1) {
    :root{
        --titlebig: 79px;
        --titlespan: 85px;
        --title: 52px;
    }
}
@media (min-width: $q-laptop) {
    :root{
        --titlebig: 88px;
        --titlespan: 100px;
    }
}

@media (min-width: $q-desktop) {
    :root{
        --footer: 16px;
        --text: 20px;
        --link: 25px;
        --titlelittle: 16px;
        --title: 71px;
        --titlebig: 109px;
        --titlespan: 121px;
    }
}
@media (min-width: $q-desktop2) {
    :root{
        --footer: 18px;
        --text: 22px;
        --link: 27px;
        --titlelittle: 18px;
        --title: 78px;
        --titlebig: 120px;
        --titlespan: 130px;
    }
}